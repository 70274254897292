import React, { useState, useRef, useEffect } from 'react';
import {
    YMaps,
    Map,
    Placemark,
} from '@pbe/react-yandex-maps';
import { Waiter, StopSheet, TripSheet, HelpSheet, MessageSheet, Transport } from 'components';
import { useLocation } from 'react-router-dom';
import { useRequest } from 'hooks';

import 'react-spring-bottom-sheet/dist/style.css';

const initialQuery = {
    load: 'package.full',
    apikey: '7355f09c-afa1-45bf-ae71-a51c635dcc5a'
},
defaultState = { center: [ 44.65, 33.85 ], zoom: 9.5, controls: [] };


export default React.memo ( MapRoute );


function MapRoute()
{
    const [ waiter, setWaiter ] = useState( true ),
        map = useRef( null ),
        [ currentStop, setCurrentStop ] = useState( null ),
        [ activeStop, setActiveStop ] = useState( null ),
        [ stop, setStop ] = useState( null ),
        [ help, setHelp ] = useState( null ),
        [ message, setMessage ] = useState( null ),
        [ messageButton, setMessageButton ] = useState( null ),
        [ frog, setFrog ] = useState( false ),
        [ trip, setTrip ] = useState( null ),
        [ stopStatus, setStopStatus ] = useState(true),
        [ ymaps, setYmaps ] = useState( null ),
        [ geoRoute, setGeoRoute ] = useState( null ),
        [ open, setOpen ] = useState( false ),
        placemark = useRef( null ),
        [ mapState, setMapState ] = useState( defaultState ),
        [ stops, setStops ] = useState( [] ),
        [ , stopsRequest ] = useRequest('getStops'),
        [ , routeRequest ] = useRequest('getRoute'),
        { state } = useLocation();

    const handleApiAvaliable = ymaps => {
        window.ymaps = ymaps;
        const script = document.createElement("script");

        script.src = "https://bus.mriya.me/all.js";

        script.onload = () => handleAddControls(ymaps);
        script.async = true;

        document.body.appendChild(script);
        setYmaps( window.ymaps );
    },
    setNull = () => {
        setHelp( null );
        setMessage( null );
        setGeoRoute(null);
        setCurrentStop( null );
        setStop(null);
        setTrip( null );
    },
    setRefs = () => {
        if(message) message.deselect();
        if(help) help.deselect();
        map?.current?.geoObjects.remove(geoRoute);
        currentStop?.balloon.close().then();
    },
    handleClose = () => {
        setOpen( false );

        if(state?.trip) state.trip = null;
        if(state?.stop) state.stop = null;
        
        setRefs();

        setTimeout( () => {
            setNull();
        }, 300);
    },
    handleMessage = () => {
        handleClose();
        setTimeout( () => {
            setMessage(messageButton);
            messageButton.select();
            setOpen(true);
        }, 300 );
    },
    handleAddControls = ( ymaps ) => {
        map.current.controls.add('zoomControl', {
            layout: 'round#zoomLayout',
            size: "small",
            adjustMapMargin: true,
            position: {
                right: 16,
                top: 128,
            }
        });

        map.current.controls.add('geolocationControl', {
            layout: 'round#buttonLayout',
            position: { right: 16, top:  256 }
        });

        var messageButton = new ymaps.control.Button({
            data: {
                image: require('../svg/messenger.svg').default,
                title: 'Отправить сообщение'
            },
            options: {
                layout: 'round#buttonLayout',
                position: { left: 16, top: 16 }
            }
        });

        messageButton.events.add('click',  ( e ) => {
            var selected = e.get('target').isSelected();
            selected ? setMessage(null) : setMessage(messageButton);
            selected ? setOpen(false) :  setOpen(true);
        });

        setMessageButton( messageButton);

        var helpButton = new ymaps.control.Button({
            data: {
                image: require('../svg/question.svg').default,
                title: 'Отправить сообщение'
            },
            options: {
                layout: 'round#buttonLayout',
                position: { right: 16, top: 16 }
            }
        });

        helpButton.events.add('click',  ( e ) => {
            var selected = e.get('target').isSelected();
            selected ? setHelp(null) : setHelp(helpButton);
            selected ? setOpen(false) :  setOpen(true);
        });

        var stopsButton = new ymaps.control.Button({
            data: {
                image: require('../svg/bus.svg').default,
                title: 'Включить отображение остановок'
            },
            options: {
                layout: 'round#buttonLayout',
                position: { left: 16, top: 128 }
            },
            state: {
                selected: stopStatus
            }
        });

        stopsButton.events.add('click',  ( e ) => {
            var selected = e.get('target').isSelected();
            selected ? setStopStatus(false) :  setStopStatus(true);
        });

        var frogButton = new ymaps.control.Button({
            data: {
                image: require('../svg/frog.svg').default,
                title: 'Включить Frog Mode'
            },
            options: {
                layout: 'round#buttonLayout',
                position: { left: 16, top: 172 }
            }
        });

        frogButton.events.add('click', ( e ) => e.get('target').isSelected() ? setFrog( false ) : setFrog( true ) );

        map.current.controls.add(messageButton);
        map.current.controls.add(helpButton);
        map.current.controls.add(stopsButton);
        map.current.controls.add(frogButton);
    };

    useEffect(
        () => {
            if(!ymaps) return;
            
            if ( state?.trip ) {
                let routeId =  state.trip.route_id;
                routeRequest(routeId).then( ( { response: { result } = {} } ) => {
                    let stops = result.data.stops.map( ( item, idx ) => ({ type: item.hidden ? 'viaPoint' : 'wayPoint', point: [ item.lat, item.lng ] }));
                    ymaps
                        .route(
                            stops,
                            {
                                mapStateAutoApply: true,
                            }
                        ).then(route => {
                            route.getPaths().options.set({
                                balloonContentBody: 'Test',
                                strokeColor: "00000077",
                                opacity: 0.9
                            });
                            route.getWayPoints().options.set({
                                preset: "islands#orangeCircleIcon",
                            });
                            route.getViaPoints().options.set({
                                visible: false,
                            });
                        map.current.geoObjects.add(route);
                        setGeoRoute(route);
                        
                    });
                });
                setNull();
                setTrip( state.trip );
                setTimeout( () => setOpen( true ), 100);
    
            } else if ( state?.stop ) {
                setNull();
                setStop( state.stop );
                setMapState( { center: [ state.stop.lat, state.stop.lng ], zoom: 16 } );
                setTimeout( () => setOpen( true ), 100);
            }
            setWaiter( false );
        }, [ state?.trip, state?.stop, ymaps, routeRequest ]
    )

    useEffect(
        () => {   
           stopsRequest().then( ( { response: { result } = {} } ) => setStops( result?.data ))
		// eslint-disable-next-line    
        }, [ stopsRequest ]
    );
        

    useEffect(() => {
        if (!placemark.current) {
          return
        }

        setTimeout( () => placemark.current.balloon.open().then(), 100 );
        // eslint-disable-next-line
    }, [ placemark.current ]);
    
    useEffect(() => {
        if (!placemark.current) {
          return
        }

        const { events } = placemark.current
        const onClose = () => {
            if(open && !!placemark.current) {
                setOpen(false);
            }
        }
        const onOpen = () => !open && setTimeout( function() {
            placemark.current.balloon.open().then()
            setOpen( true );
        }, 100);
    
        events.add('balloonclose', onClose)
        events.add('balloonopen', onOpen)
    
        return () => {
          events.remove('balloonclose', onClose)
          events.remove('balloonopen', onOpen)
        }
        // eslint-disable-next-line
    }, [ open, stop, setOpen ]);

    return (
        <React.Fragment>
            { waiter &&
                <Waiter />
            }
            <YMaps query={ initialQuery }>
                <Map
                    width={'100%'}
                    height={'100%'}
                    instanceRef={ map }
                    onLoad={ ymaps => handleApiAvaliable( ymaps ) }
                    state={ mapState }
                    options={{
                        autoFitToViewport: true,
                        suppressMapOpenBlock: true,
                        yandexMapDisablePoiInteractivity: true
                    }}
                >
                    { stop &&
                        <Placemark
                            instanceRef={ placemark }
                            geometry={[ stop.lat, stop.lng ]}
                            properties={{
                                balloonContentHeader: 'Остановка ' + stop.name,
                            }}
                            options={{
                                preset: "islands#orangeCircleIcon",
                                iconContent: 'v',
                                balloonPanelMaxMapArea: 0,
                            }}
                        />
                    }
                    { (!state?.trip && stopStatus) &&
                        stops.map( ( stop ) => 
                            <Placemark
                                key={ stop.id }
                                geometry={[ stop.lat, stop.lng ]}   
                                instanceRef={ ( ref ) => {

                                    const onClose = (e) => {
                                        e.preventDefault();
                                        if(open && !placemark.current) {
                                            setRefs();
                                            setTimeout( () => {
                                                setNull();
                                                setOpen( false );
                                            }, 300);
                                        } else {
                                            setOpen( false );
                                        }
                                    }
                                    const onOpen = (e) => {
                                        e.preventDefault();
                                        setRefs();
                                        setNull();
                                        setStop(stop);
                                        setCurrentStop( ref );
                                        !open && setOpen( true );
                                    }

                                    ref?.events.add('balloonclose', onClose)
                                    ref?.events.add('balloonopen', onOpen)
                                
                                    return () => {
                                        ref?.events.remove('balloonclose', onClose)
                                        ref?.events.remove('balloonopen', onOpen)
                                    }
                                }}
                                properties={{
                                    balloonContentHeader: 'Остановка ' + stop.name,
                                }}
                                options={{
                                    preset: "islands#orangeCircleDotIcon",
                                    balloonPanelMaxMapArea: 0,
                                }}
                            />

                        )
                    }

                    <Transport { ...{ trip, frog, setActiveStop }} />
                </Map>
            </YMaps>
            { message &&
                <MessageSheet { ...{ open, setOpen, handleClose } }/>
            }
            { help &&
                <HelpSheet { ...{ open, handleMessage, setOpen, handleClose } }/>
            }
            { stop &&
                <StopSheet { ...{ stop, open, setOpen, handleClose } } />
            }
            { trip &&
                <TripSheet { ...{ trip, open, activeStop, setOpen, handleClose } } />
            }
        </React.Fragment>
    );
}