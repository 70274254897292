import React, { useState, useEffect } from 'react';
import { useRequest } from 'hooks';
import { Bus } from 'components';
import { findNearest } from 'geolib';


export default function Transport({ trip, frog, setActiveStop })
{
    

    const [ , request ] = useRequest('getTransport'),
        [ transport, setTransport ] = useState( [] ),
        handleUpdate = () => request().then( ( { response: { result } = {} } ) => {
            var transport;
            if( trip != null && trip.transport.length > 0 ) {
                var activeTransport = trip.transport.map( (bus) => bus.id );
                transport = result?.data.filter( (bus) => activeTransport.includes(bus.id));
            } else {
                transport = result?.data;
            }
            setTransport( transport );
        }
    );
    
    useEffect(
        () => {      
           handleUpdate();
		// eslint-disable-next-line    
        }, [ request ]
    );

    useEffect(
        () => {      
           var stops = trip?.route?.stops.map( (stop) => ({ 'latitude': stop.lat, 'longitude': stop.lng }) );
           if(stops) {
            transport.forEach( (value) => {
                var bus = ({ 'latitude': value.lat, 'longitude': value.lng });
                var nearest = findNearest(bus, stops);
                if( nearest !== stops[0] && nearest !== stops[stops.length - 1] ) {
                    var active = trip.route.stops.findIndex( ( value ) => value.lat === nearest.latitude && value.lng === nearest.longitude );
                    setActiveStop(trip.route.stops[active].pivot.sort_order);
                }
                
            } );
           }
		// eslint-disable-next-line    
        }, [ transport ]
    );

    useEffect(
        () => {     
            const interval = setInterval(function() {
                handleUpdate();
            }, 1000 );
            return () => clearInterval(interval);
        },
    );
    /*setTimeout( () => {
        var today = new Date();
        var diffTime = Math.abs(new Date( bus.last_msg ) - today);
        var time = diffTime / 10000;
        var point = computeDestinationPoint([ bus.lng, bus.lat ], bus.speed * time, bus.course);
        setDestination([...destination, { 'id': bus.id, point: point } ] );
    }, 10000);*/

    return (
        <React.Fragment>
        { transport.length > 0 &&
            transport.map( ( bus ) =>
                <Bus
                    key={ bus.id }
                    bus={ bus }
                    frog={ frog }
                />
            )
        }
        </React.Fragment>
    );
}