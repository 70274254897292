import * as React from 'react';
import { MapRoute, Trips, Stops } from 'routes';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { NavigationBar } from 'components';
import './App.css';

function App() {

  let routes = [
    '/',
    '/trips',
    '/stops'
  ];
  
  return (
    <BrowserRouter>
        <div style={{ height: 'calc(100% - 56px)', overflow: 'scroll' }}>
          <Routes>
            <Route path={ routes[0] } element={<MapRoute />} />
            <Route path={ routes[1] } element={<Trips />} />
            <Route path={ routes[2] } element={<Stops />} />
          </Routes>
        </div>
        <NavigationBar routes={ routes } />
    </BrowserRouter>
  );
}

export default App;
