import React, { useRef } from 'react';
import {
    IconButton,
    Box,
    Typography,
    Divider,
    Button,
} from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Cancel, DirectionsBus, ArrowBack, ArrowForward, Commute } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

import 'react-spring-bottom-sheet/dist/style.css'


export default function StopSheet( { stop, open, setOpen, handleClose } )
{
    const focusRef = useRef(),
        sheetRef = useRef(),
        navigate = useNavigate();

    function routesFrom ( route ) {
      return route.trips.filter( ( trip ) => trip.direction === 1 );
    }

    function routesTo ( route ) {
        return route.trips.filter( ( trip ) => trip.direction === 0 );
    }

    function getTransport ( route ) {
        var transport = route.trips.filter( (item) => item.transport != null ).map( (item) => item.transport ).flat(1);
        var current = transport.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.place === value.place && t.name === value.name
            )));
        return current;
    }

    return (
        <BottomSheet
            open={ open }
            blocking={ false }
            ref={ sheetRef }
            initialFocusRef={ focusRef }
            defaultSnap={({ maxHeight }) => maxHeight * 0.5}
            snapPoints={({ maxHeight }) => [
                maxHeight,
                maxHeight * 0.5,
                maxHeight * 0.13,
            ]}
            //expandOnContentDrag={ true }
            header={
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" flexDirection="column" alignItems="start">
                        <Typography variant="h6" textAlign="start" style={{ fontWeight: 600 }}>
                            { stop.name }
                        </Typography>
                        <Typography variant="subtitle2" style={{ color: '#888' }}>
                            Остановка автобуса
                        </Typography>
                    </Box>
                    <IconButton onClick={ handleClose } edge="end" sx={{ marginTop: -1 }} >
                        <Cancel color="disabled" />
                    </IconButton>
                </Box>
            }
        >
            { stop.routes.map( (route) => {
                var tripsTo = routesTo(route);
                var tripsFrom = routesFrom(route);
                var transport = getTransport(route);

                return (
                    <React.Fragment key={ route.id }>
                        <Box px={ 2 } pb={ 1 }>
                            <Box display="flex" py={ 1 } alignItems="center">
                                <DirectionsBus color="success" />
                                <Box display="flex" flexDirection="column" ml={ 2 } style={{ width: '100%' }}>
                                    <Typography>
                                        { route.name }
                                    </Typography>
                                </Box>
                            </Box>
                            { tripsTo.length > 0 &&
                                <Box display="flex" justifyContent="space-between" alignItems="start" mb={ 1 }>
                                    <Box display="flex" alignItems="start">
                                        <ArrowForward color="disabled" />
                                        <Typography variant="subtitle1" noWrap style={{ marginLeft: 16, fontWeight: 600 }}>В отель:</Typography>
                                    </Box>
                                    <Box display="flex" sx={{ flexWrap: "wrap" }} justifyContent="end">
                                        { tripsTo.map( ( trip ) =>
                                            <Button
                                                color="primary"
                                                key={ trip.id }
                                                onClick={ () => navigate('/', { state: { trip: trip, stop: null }})} variant="outlined">
                                                    <Typography color="primary" style={{ textAlign: 'end', fontWeight: 600 }}>{ trip.start }</Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            }
                            { tripsFrom.length > 0 &&
                                <Box display="flex" justifyContent="space-between" alignItems="start" mb={ 1 }>
                                    <Box display="flex" alignItems="start">
                                        <ArrowBack color="disabled" />
                                        <Typography variant="subtitle1" noWrap style={{ marginLeft: 16, fontWeight: 600  }}>Из отеля:</Typography>
                                    </Box>
                                    <Box display="flex" sx={{ flexWrap: "wrap" }} justifyContent="end">
                                        { tripsFrom.map( ( trip ) =>
                                            <Button
                                                color="primary"
                                                key={ trip.id }
                                                onClick={ () => navigate('/', { state: { trip: trip, stop: null }})} variant="outlined">
                                                    <Typography color="primary" style={{ fontWeight: 600 }}>{ trip.start }</Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            }
                            {  transport.length > 0 &&
                            
                                <Box display="flex" justifyContent="flex-start" alignItems="center" mb={ 1 }>
                                    <Box display="flex" alignItems="center" mr={ 2 }>
                                        <Commute color="success" />
                                    </Box>
                                    <Typography variant="h6">
                                        { transport.map( ( bus ) => bus.name ).join(', ') }
                                    </Typography>
                                </Box>   
                            }
                        </Box>
                        <Divider light />
                    </React.Fragment>
                );
            }
        )}
        </BottomSheet>
    );
}