import React, { useRef, useState, useCallback, useEffect } from 'react';
import {
    IconButton,
    Box,
    Typography,
    TextField,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import Lottie from 'react-lottie';
import { Cancel } from '@mui/icons-material';
import { useDeviceData } from 'react-device-detect';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useRequest } from 'hooks';

import * as successAnimation from '../success.json'

import 'react-spring-bottom-sheet/dist/style.css';


export default function MessageSheet( { open, setOpen, handleClose } )
{
    const focusRef = useRef(),
        sheetRef = useRef(),
        data = JSON.stringify( useDeviceData() ),
        [ name, setName ] = useState( '' ),
        [ nameError, setNameError ] = useState( false ),
        [ messageError, setMessageError ] = useState( false ),
        [ message, setMessage ] = useState( '' ),
        [ personal, setPersonal ] = useState( false ),
        [ phone, setPhone ] = useState( '' ),
        [ status, setStatus ] = useState( false ),
        [ , request ] = useRequest('postMessage'),
        sendMessage = useCallback (
			() => {
                setNameError(false);
                setMessageError(false);
                if( name !== '' && message !== '' ) {
                    request( { name, phone, message, data } ).then( ( { response } ) => setStatus( response.result.status === 'success' ) );
                }
                if( name === '' ) {
                    setNameError(true);
                }
                if( message === '' ) {
                    setMessageError(true);
                }
            },
            [ data, message, name, phone, request ]
        );

    useEffect(
        () => {
            if(status) setTimeout( handleClose, 3000 );
        }, [ handleClose, status ]
    );

    return (
        <BottomSheet
            open={ open }
            blocking={ false }
            ref={ sheetRef }
            initialFocusRef={ focusRef }
            defaultSnap={({ maxHeight }) => maxHeight * 0.6 }
            snapPoints={({ maxHeight }) => [
                maxHeight * 0.6,
                maxHeight * 0.07,
            ]}
            //expandOnContentDrag={ true }
            header={
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" flexDirection="column" alignItems="start">
                        <Typography variant="h6">
                            Отправить сообщение
                        </Typography>
                    </Box>
                    <IconButton onClick={ handleClose } edge="end" sx={{ marginTop: -1 }} >
                        <Cancel color="disabled" />
                    </IconButton>
                </Box>
            }
        >
            { status  ?
                <Box p={ 2 } textAlign="center">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true, 
                            animationData: successAnimation,
                        }}
                        height={ 200 }
                        width={ 200 }
                    />
                    <Typography variant="h6">Сообщение отправлено!</Typography>
                </Box>
                :
                <Box p={ 2 }>
                    <TextField
                        onChange={ (e) => setName( e.target.value ) }
                        fullWidth
                        label="Ваше имя"
                        helperText={ nameError && "Введите ваше имя" }
                        variant="outlined"
                        size="small"
                        required
                        error={ nameError }
                    />
                    <Box mt={ 2 }>
                    <MuiPhoneNumber
                        defaultCountry={'ru'}
                        onChange={ ( value ) => setPhone( value ) }
                        fullWidth
                        label="Ваш телефон"
                        variant="outlined"
                        size="small"
                    />
                    </Box>
                    <Box mb={ messageError ? 1 : 2 } mt={ nameError ? 1 : 2 }>
                        <TextField
                            onChange={ (e) => setMessage( e.target.value ) }
                            fullWidth
                            label="Ваше сообщение"
                            helperText={ messageError && "Введите текст сообщения" }
                            variant="outlined"
                            multiline
                            rows={ 4 }
                            size="small"
                            required
                            error={ messageError }
                        />
                    </Box>
                    <FormGroup>
                        <FormControlLabel
                            sx={{ alignItems: 'flex-start' }}
                            control={<Checkbox checked={ personal } onChange={ () => setPersonal(!personal) } />}
                            label={
                                <Box mb={ 2 }>
                                   <span>Cоглашаюсь на обработку <a target="_blank" rel="noreferrer" href="https://mriyaresort.com/upload/medialibrary/5f5/t16axbwoyfvts0nezwutpfjpij5401qx.pdf">персональных данных</a></span>
                                </Box>
                                }
                        />
                    </FormGroup>
                    <Button
                        onClick={ sendMessage }
                        fullWidth
                        variant="contained"
                        disabled={ !personal }
                    >
                        Отправить
                    </Button>
                </Box>
            }
        </BottomSheet>
    );
}