import { useState, useEffect } from 'react';
import { 
    BottomNavigation,
    BottomNavigationAction,
} from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import {
    NearMeOutlined,
    MapOutlined,
    DirectionsBusOutlined
} from '@mui/icons-material';

export default function NavigationBar( { routes } )
{
    const [ value, setValue ] = useState(0),
        navigate = useNavigate(),
        location = useLocation(),
        handleNavigation = (_, newValue) => {
            setValue(newValue);
            navigate(routes[newValue]);
        };
    
    useEffect (
        () => {
            if( location.pathname !== routes[value] ) {
                navigate(routes.findIndex( (element) => element === location.pathname));
            }
            setValue(routes.findIndex( (element) => element === location.pathname));
        }, [ routes, location, navigate, value ]
    );

    return (
        <BottomNavigation
            showLabels
            value={ value }
            onChange={ handleNavigation }
        >
            <BottomNavigationAction label="Карта" icon={<NearMeOutlined />} />
            <BottomNavigationAction label="Маршруты" icon={<MapOutlined />} />
            <BottomNavigationAction label="Остановки" icon={<DirectionsBusOutlined />} />
        </BottomNavigation>
    );
}