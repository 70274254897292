import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    AppBar,
    Toolbar,
    InputBase,
    Box
} from '@mui/material';
import { Search, ChevronRight } from '@mui/icons-material';
import { useRequest } from 'hooks';
import { Waiter } from 'components';
import { useNavigate } from "react-router-dom";


export default React.memo ( Stops );

function Stops()
{
    const [ stops, setStops ] = useState([]),
        navigate = useNavigate(),
        [ waiter, request ] = useRequest ( 'getStops' ),
        [ allStops, setAllStops ] = useState([]),
        handleSearch = ( event ) => setStops( allStops.filter( ( stop ) => stop.name.toLowerCase().includes(event.target.value.toLowerCase())) );

    useEffect (
		() => {
			request ()
				.then ( ( { response: { result } = {} } ) => {
                    setAllStops( result?.data );
                    setStops( result?.data );
                }
            )
        }, [ request ]
    );

    return (
        <React.Fragment>
            <AppBar
                position="sticky"
                color="white"
                enableColorOnDark
                sx={{ height: 72 }}
                elevation={1}
            >
                <Toolbar sx={{ marginTop: 1 }}>
                    <Box flex justifyContent="center" pl={ 1 } pr={ 2 }>
                        <Search />
                    </Box>
                    <InputBase
                        onChange={ handleSearch }
                        placeholder="Поиск..."
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Toolbar>
            </AppBar>
            <List
                disablePadding
            >
                { !waiter ? stops.map( ( stop ) => 
                    <ListItem
                        key={ stop.id }
                        disablePadding
                    >
                        <ListItemButton onClick={ () => navigate('/', { state: { stop: stop } } ) }>
                            <ListItemText primary={ stop.name } />
                            <ChevronRight />
                        </ListItemButton>
                    </ListItem>
                )
                :
                <Waiter />
            }
            </List>
        </React.Fragment>
    );
}