import React, { useState, useEffect } from 'react';
import {
    Placemark,
} from '@pbe/react-yandex-maps';
//import { computeDestinationPoint } from 'geolib';

const frogSrc = {
    "/images/transport/sprinter.png": "/images/frog/sprinter.png",
    "/images/transport/iveco.png": "/images/frog/iveco.png",
    "/images/transport/next.png": "/images/frog/next.png",
    "/images/transport/scania.png": "/images/frog/scania.png"
};


export default function Bus( { bus, frog } ) {
    const [ image, setImage ] = useState( bus.image );
    /*const [ destination, setDestination ] = useState( { lat: bus.lat, lng: bus.lng } );

    useEffect(
        () => {
            const interval = setInterval(
                () => {
                    var today = new Date();
                    var diffTime = Math.abs(new Date( bus.last_msg ) - today);
                    var time = diffTime / 1000;
                    //console.warn(bus.id,  bus.speed * time);
                    var point = computeDestinationPoint([ bus.lng, bus.lat ], bus.speed * time, bus.course);
                    setDestination( { lat: point.latitude, lng: point.longitude } );
                }, 100
            );
            return () => clearInterval(interval);
        }, [ bus ]
    )*/
    useEffect(
        () => {
            setImage( frog ? frogSrc[ bus.image ] : bus.image );
        }, [ frog, bus ]
    )

    return (
            <Placemark
                geometry={ [ bus.lat, bus.lng ] }
                properties={{
                    iconContent: '<img width="32px" src="' + image + '" />', 
                    balloonContentHeader: '<div style="display:flex; align-items: center"><img style="padding-right: 8px" width="32px" src="' + image + '" /> ' + bus.name + '</div>',
                    //balloonContentBody: '<b>Маршруты:</b><br />' + bus.trips.map( (trip) => '<a href="" >' + trip.route.name + '</a>' ).join('<br />'),
                    balloonContentFooter: '<small>Последнее сообщение<br />' + bus.last_msg + '</small>'
                }}
                options={{
                    preset: "islands#greenIcon",
                    balloonPanelMaxMapArea: 0,
                    // The balloon will open and close when the placemark icon is clicked.
                    // hideIconOnBalloonOpen: false
                }}
            />
    );
}