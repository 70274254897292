import { createTheme } from '@mui/material/styles';


export default createTheme ({
	palette: {
		primary: {
			main: '#DEA166'
		},
		secondary: {
			main: '#DEA166'
		},
		white: {
			main: '#FFF'
		}
	},
	components: {
		// Name of the component
		MuiButton: {
		  styleOverrides: {
			// Name of the slot
			root: {
			  // Some CSS
			  fontSize: '1rem',
			  color: '#fff',
			}
		  },
		},
		MuiStepIcon: {
			styleOverrides: {
				text: {
					fill: '#fff'
				}
			}
		}
	  },
});
