import React, { useRef } from 'react';
import {
    IconButton,
    Link,
    Box,
    Typography,
} from '@mui/material';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Cancel, Apple, Android, NearMeOutlined, DirectionsBusOutlined } from '@mui/icons-material';
import 'react-spring-bottom-sheet/dist/style.css';


export default function HelpSheet( { open, handleMessage, setOpen, handleClose } )
{
    const focusRef = useRef(),
        sheetRef = useRef();

    return (
        <BottomSheet
            open={ open }
            blocking={ false }
            ref={ sheetRef }
            initialFocusRef={ focusRef }
            defaultSnap={({ maxHeight }) => maxHeight}
            snapPoints={({ maxHeight }) => [
                maxHeight,
                maxHeight * 0.07,
            ]}
            //expandOnContentDrag={ true }
            header={
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box display="flex" flexDirection="column" alignItems="start">
                        <Typography variant="h6">
                            Приложение MRIYA.BUS
                        </Typography>
                    </Box>
                    <IconButton onClick={ handleClose } edge="end" sx={{ marginTop: -1 }} >
                        <Cancel color="disabled" />
                    </IconButton>
                </Box>
            }
        >
            <Box p={ 2 }>
                <Box>
                    Мы создали удобный сервис для сотрудников <b>Mriya Resort & SPA</b> который позволяет не только ознакомиться с маршрутами и расписанием бесплатных автобусов, но и следить в реальном времени за их движением. Теперь вы можете заранее рассчитать время и подойти на остановку к моменту прибытия автобуса.
                    <br />
                    <br />
                    <Typography variant="h6">Как работает приложение?</Typography>
                        На каждый автобус установлены устройства ГЛОНАСС/GPS, которые передают свои геолокационные координаты на наш сервер, что позволяет пользователям в режиме реального времени отслеживать фактическое местоположение транспортного средства в виде меток на карте.
                    
                    <br />
                    <br />
                    <Typography variant="h6">Как пользоваться приложением?</Typography>
                    <ol>
                        <li>
                            Пользоваться через браузер или установить приложение на мобильное устройство — будет удобнее (если у вас iOS, используйте Safari).
                        </li>
                        <li>
                            Выбрать нужную остановку (для удобства можно разрешить приложению отслеживать вашу геолокацию).
                        </li>
                        <li>
                            При нажатии на точку открывается расписание для выбранной остановки, а также маршрут и время до прибытия автобуса.
                        </li>
                    </ol>
                    <Typography variant="h6" sx={{ lineHeight: 1.2 }}>Как разрешить приложению отслеживать мою геолокацию?</Typography>
                    Нажмите на иконку со стрелкой <NearMeOutlined fontSize="small" /> (справа на карте), приложение запросит доступ на отслеживание геопозиции.
                    <br />
                    <br />
                    <Typography variant="h6" sx={{ lineHeight: 1.2 }}> Как найти нужную остановку и где посмотреть маршруты?</Typography>
                    В нижней части экрана есть меню. Нажмите на иконку с автобусом <DirectionsBusOutlined /> («Остановки») и выберете нужную из всплывающего списка. Или можно найти точку на карте и нажать на нее. 
                    В том же меню есть «Маршруты». Выберете из всплывающего списка нужный рейс, чтобы посмотреть маршрут автобуса. 
                    <br />
                    <br />
                    <Typography variant="h6"> Как установить приложение?</Typography>
                    <Box mt={ 1 } display="flex" justifyContent="space-between">
                    <Typography>Видеоинструкция:</Typography>
                    <Box display="flex" alignItems="center">
                        <Apple />
                        <Link href="https://youtube.com/shorts/mHYFOpEvPe8" underline="hover" color="inherit" target="_blank">iOS</Link>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Android />
                        <Link href="https://youtube.com/shorts/Ravkaag8deQ" underline="hover" color="inherit" target="_blank">Android</Link>
                    </Box>
                </Box>
                <br /><br />
                    <i>Уважаемые коллеги!<br /><br />
                    Мы впервые создаем для вас транспортное приложение и хотим, чтобы оно было максимально простым и удобным. Для этого мы сделали <Link onClick={ handleMessage } underline="hover" target="_blank">форму обратной связи</Link> и будем благодарны за ваши отзывы и предложения, что особенно важно на этапе запуска для стабильной работы. Мы учтем все комментарии, поправим и улучшим при необходимости.
                    <br /><br />
                    Хорошей дороги!</i>
                </Box>
                <Box mt={ 3 } display="flex" justifyContent="space-between">
                    <Typography variant="caption">
                        Сделано с ❤️
                    </Typography>
                    <Typography variant="caption">
                        <a style={{ color: '#000', textDecoration: 'none' }} rel="noreferrer" target="_blank" href="https://t12my.ru">t12my</a>
                    </Typography>
                </Box>
            </Box>
        </BottomSheet>
    );
}