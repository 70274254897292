import React, { useEffect, useRef, useState } from 'react';
import {
    Step,
    Stepper,
    StepLabel,
    IconButton,
    Box,
    Typography,
    Skeleton
} from '@mui/material';
import { Commute } from '@mui/icons-material';
import { useRequest } from 'hooks';

import { BottomSheet } from 'react-spring-bottom-sheet';
import { Cancel } from '@mui/icons-material';

import 'react-spring-bottom-sheet/dist/style.css'


export default function TripSheet( { trip, open, activeStop, handleClose } )
{
    const activeStep = useRef(),
        [ currentStop, setCurrentStop ] = useState(activeStop),
        [ , routeRequest ] = useRequest('getRoute'),
        [ route, setRoute ] = useState(null),
        direction = trip.direction === 0,
        reverseName = ( name ) => {
           var stops = name.split(' - ');
           stops.reverse();
           return stops.join(' - ');
        },
        getStopsOrder = ( direction, stops ) => {
            var newStops = !!stops ? Object.assign([], stops) :  Array.from(Array(10)).map(( v, i ) => ({ id: i + 1 }));
            if(!!stops && !direction) stops.reverse();

            return newStops;
        },
        focusRef = useRef(),
        sheetRef = useRef();

    useEffect(
        () => {
            async function fetchData() {
               await routeRequest( trip.route_id ).then( ( { response: { result } = {} } ) => {
                    setRoute(result.data);
                });
            
            }
            fetchData();
        },
        [  routeRequest, trip.route_id ]
    );

    useEffect( 
        () => {
            setTimeout( () => {
                if( activeStep.current ) activeStep.current.scrollIntoView();
            }, 100);
        },
        [ activeStep ]
    );

    useEffect( 
        () => {
            setCurrentStop( activeStop )
        },
        [ activeStop ]
    );

    return (
        <BottomSheet
            open={ open }
            blocking={ false }
            ref={ sheetRef }
            initialFocusRef={ focusRef }
            defaultSnap={ ({ maxHeight }) => maxHeight * 0.5 }
            snapPoints={({ maxHeight }) => [
                maxHeight,
                maxHeight * 0.5,
                route != null && route.name.length > 35 ? maxHeight * 0.13 : maxHeight * 0.11,
            ]}
            //expandOnContentDrag={ true }
            header={
                <Box display="flex" flexDirection="column">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex"> 
                            <Typography variant="subtitle1" textAlign="start" style={{ marginLeft: 1}}>
                                { route === null ?
                                    <Skeleton
                                        variant="text" 
                                        width={ 'calc(100vw - 100px)'}
                                        sx={{ fontSize: '1rem' }}
                                    />
                                    :
                                    direction ? route.name : reverseName(route.name)
                                }
                            </Typography>
                        </Box>
                        <IconButton onClick={ handleClose } edge="end" sx={{ marginTop: -1 }} >
                            <Cancel color="disabled" />
                        </IconButton>
                    </Box>
                    {  trip.transport.length > 0 &&
                        <Box display="flex" justifyContent="flex-start" alignItems="center" mb={ 1 }>
                            <Box display="flex" alignItems="center" mr={ 2 }>
                                <Commute color="success" />
                            </Box>
                            <Typography variant="h6">
                                { trip.transport.map( ( bus ) => bus.name ).join(', ') }
                            </Typography>
                        </Box>
                    }
                </Box>
            }
        >
            <Box px={ 2 } py={ 1 }>
                <Stepper activeStep={ currentStop ?? 0 } orientation="vertical">
                    { getStopsOrder(direction, route?.stops ).map( (stop, index) => {

                    return (
                        <Step ref={ currentStop === stop?.pivot?.sort_order ? activeStep : undefined } key={ stop?.id } completed={ false }>
                            <StepLabel>
                                <Typography variant="subtitle1">{ route === null ? <Skeleton /> : stop?.name }</Typography>
                            </StepLabel>
                        </Step>
                        )}
                    )}
                </Stepper>
            </Box>
        </BottomSheet>
    );
}