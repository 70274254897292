import React, { useEffect, useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Divider,
    Tab,
    Tabs,
    Box,
    ListSubheader,
    Typography,
    IconButton,
    Grid,
    Card,
    CardActionArea,
    CardContent,
    Button
} from '@mui/material';
import { Waiter } from 'components';
import { useRequest } from 'hooks';
import { useNavigate } from "react-router-dom";
import { ArrowForward, ArrowBack, GridView, ViewDay } from '@mui/icons-material';

const directions = [
    'В отель',
    'Из отеля'
]


export default function Trips()
{
    const [ value, setValue ] = useState(0),
        [ trips, setTrips ] = useState([]),
        [ isGrid, setIsGrid ] = useState(false),
        [ waiter, request ] = useRequest ( 'getTrips' ),
        handleChange = (event, newValue) => {
            setValue(newValue);
        };

    useEffect (
		() => {
			request ()
				.then ( ( { response: { result } = {} } ) => {
                    setTrips( result?.data );
                }
            )
        }, [ request ]
    );

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="fullWidth"
                    indicatorColor="secondary"
                    value={ value }
                    onChange={ handleChange }
                    aria-label="route tabs"
                    sx={{ width: '100%' }}
                >
                    { directions.map( ( element, index ) => <Tab key={ index } icon={ index === 0 ? <ArrowForward /> : <ArrowBack />} iconPosition={index === 0 ? 'start' : 'end' } label={ element } {...tabProps(index)} /> )}
                </Tabs>
                <Box alignItems="center" justifyContent="center" sx={{ display: 'flex', width: 80 }}>
                    <IconButton onClick={ () => setIsGrid(!isGrid) } color="primary" aria-label="Переключить вид">
                        { !isGrid ? <GridView /> : <ViewDay /> }
                    </IconButton>
                </Box>
            </Box>
            <Divider />
            <Box style={{ height: 'calc( 100% - 80px )', overflowY: 'scroll' }}>
                { ( !waiter && trips.length > 0 )  ?
                    <React.Fragment>
                        { directions.map( ( _, index ) =>
                            <TabPanel key={ index } value={ value } index={ index }>
                                { isGrid ?
                                    <RouteGrid
                                        trips={ trips.filter( (item) => item.direction === index ) }
                                    />
                                    :
                                    <RouteList
                                        trips={ trips.filter( (item) => item.direction === index ) }
                                    />
                                }
                            </TabPanel>
                        )}
                    </React.Fragment>
                    :
                    <Waiter />
                }
            </Box>
        </React.Fragment>
    );
}


function RouteList( { trips } ) {

    const groupedTrips = trips.reduce(function (r, a) {
        r[a.start] = r[a.start] || [];
        r[a.start].push(a);
        return r;
    }, Object.create(null)),
    reverseName = ( name ) => {
        var stops = name.split(' - ');
        stops.reverse();
        return stops.join(' - ');
    };


    const navigate = useNavigate();
    return (
        <List
            subheader={<li />}
            disablePadding
        >
            { Object.entries(groupedTrips).map(( routes ) =>
                <li key={`section-${routes[0]}`}>
                    <ul>
                        <ListSubheader disableGutters>
                            <Box py={ 1 }>
                                <Typography variant="h5" fontWeight="bold" textAlign="center">
                                    { routes[0] }
                                </Typography>
                            </Box>
                            <Divider />
                        </ListSubheader>
                        
                        { routes[1].map( (trip) =>
                            <ListItem
                                key={`item-${trip.route.id}-${trip.id}`}
                                disablePadding
                                divider
                            >
                                <ListItemButton onClick={ () => navigate('/', { state: { trip: trip } }) }>
                                    <ListItemText disableTypography primary={<Typography> { trip.direction === 0 ? trip.route.name : reverseName(trip.route.name) } </Typography>}  secondary={ <Typography variant="h6"> { trip.transport.map( (transport) => transport.name ).join(', ') }</Typography> } />
                                </ListItemButton>
                            </ListItem>
                        )}
                    </ul>
                </li>
             )}
        </List>
    );
}

function RouteGrid( { trips } ) {

    const groupedTrips = trips.reduce(function (r, a) {
        r[a.start] = r[a.start] || [];
        r[a.start].push(a);
        return r;
    }, Object.create(null)),
    reverseName = ( name ) => {
        var stops = name.split(' - ');
        stops.reverse();
        return stops.join(' - ');
    };
    const [ current, setCurrent ] = useState( null );


    const navigate = useNavigate();
    return (
        <React.Fragment>
        { !!current ?
            <Box sx={{ height: '100%' }} display="flex" flexDirection="column" justifyContent="space-between">
                <List
                    disablePadding
                >
                    { current[1].map( (trip) =>
                        <ListItem
                            key={`item-${trip.route.id}-${trip.id}`}
                            disablePadding
                            divider
                        >
                            <ListItemButton onClick={ () => navigate('/', { state: { trip: trip } }) }>
                                <ListItemText disableTypography primary={<Typography> { trip.direction === 0 ? trip.route.name : reverseName(trip.route.name) } </Typography>}  secondary={ <Typography variant="h6"> { trip.transport.map( (transport) => transport.name ).join(', ') }</Typography> } />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
                <Box display="flex" justifyContent="flex-end" sx={{ margin: 3 }}>
                    <Button size="large" variant="contained" onClick={ () => setCurrent( null ) }>
                        <Typography fontWeight="bold" textAlign="center">Обратно к выбору рейса</Typography>
                    </Button>
                </Box>
            </Box>
            :
            <Grid container spacing={2} rowSpacing={2} padding={3}>
                { Object.entries(groupedTrips).map(( routes ) =>
                    <Grid item xs={4} key={`section-${routes[0]}`}>
                        <Card>
                            <CardActionArea onClick={ () => setCurrent( routes ) }>
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'calc(100vh / ' + trips.length / 3 + ')' }}>
                                        <Typography variant="h5" fontWeight="bold" textAlign="center">
                                            { routes[0] }
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        }
        </React.Fragment>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function tabProps(index) {
    return {
      id: `route-tab-${index}`,
      'aria-controls': `route-tabpanel-${index}`,
    };
  }
  